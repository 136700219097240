import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import logo from "../../assets/images/LaFace_Logo_Warm_Red.svg"

const MobileMenu = ({ menuHandler, active, close, data }) => {
  const [isOpen, setIsOpen] = useState(false)

  let props = {
    onClick: close,
  }

  return (
    <div className={`MobileMenu__Wrapper ${active ? 'active' : ''}`}>
      <div className="MobileMenu__InnerWrapper">
        <button
          type="button"
          className="MobileMenu__CrossIcon"
          onClick={() => menuHandler()}
        >
          <svg width="13px" height="13px" viewBox="0 0 13 13">
            <g stroke="#E84734" strokeLinecap="square" strokeWidth="0.5">
              <line x1="6.5" y1="0" x2="6.5" y2="13" id="Line"></line>
              <line x1="0" y1="6.5" x2="13" y2="6.5" id="Line-2"></line>
            </g>
          </svg>
        </button>
        <div className="header__logo">
          <Link to="/" {...props}>
            <img
              src={logo}
              className="banner__logoImg "
              alt="header-logo"
            />
          </Link>
        </div>
        <div className="MobileMenu__divider" />

        <div className="MobileMenu__element">
          <Link to='https://lafacebeautyclinic.mylocalsalon.com/OnlineBooking' {...props}> 
            Book Now
          </Link>
        </div>
        <div className={`MobileMenu__element ${isOpen ? 'Services__Menu' : ''}`}>
          <div className={`${isOpen ? 'Services__head' : 'Services__Menu'}`}>
            <Link to="/treatments/" {...props}>Treatments</Link>
            <div className="Services__Add" onClick={() => setIsOpen(!isOpen)}>
              <svg className={isOpen ? 'active' : ''} width="13px" height="13px" viewBox="0 0 13 13">
                <g stroke="#E84734" strokeLinecap="square" strokeWidth="0.5">
                  <line x1="6.5" y1="0" x2="6.5" y2="13" id="Line"></line>
                  <line x1="0" y1="6.5" x2="13" y2="6.5" id="Line-2"></line>
                </g>
              </svg>
            </div>
          </div>
          { isOpen && (
            <ul className="servicesCatagories__list">
              {data.allWpService.edges.map((el, i) => (
                <li key={i} className="servicesCatagories__listElement">
                  <Link
                    to={`/service/${el.node.slug}/`}
                    className="servicesCatagories__button"
                    {...props}
                  >
                    {el.node.title}
                  </Link>
                </li>
              ))}
            </ul>
          ) }
        </div>
        <div className="MobileMenu__element">
          <Link to="/product/gift-voucher/" {...props}>Gift vouchers</Link>
        </div>
        <div className="MobileMenu__element">
          <Link to="/shop/" {...props}>Product shop</Link>
        </div>
        <div className="MobileMenu__element">
          <Link to="/contact/" {...props}>Contact</Link>
        </div>
        <div className="MobileMenu__element">
          <Link to="/notes/" {...props}>Information & Blogs</Link>
        </div>
      </div>
      <div className='MobileMenu__Overlay' onClick={menuHandler}></div>
    </div>
  )
}

export default function MobileMenuWrap(props) {
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          allWpService(sort: { order: ASC, fields: menuOrder }) {
            edges {
              node {
                title
                slug
              }
            }
          }
        }
      `}
      render={data => <MobileMenu data={data} {...props} />}
    />
  )
}
